import {useCallback} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {MantineProvider, ColorSchemeProvider, ColorScheme} from '@mantine/core';
import {MantineThemeOverride} from '@mantine/styles/lib/theme/types';
import {NotificationsProvider} from '@mantine/notifications';
import {useLocalStorage} from '@mantine/hooks';
import 'dayjs/locale/fr';

import AppRoutes from './routes';
import {colorsShades} from './theme/colors';
import {ApiResponseInterceptorProvider} from 'lib/api';

const MantineProviderTheme: MantineThemeOverride = {
    colors: {
        ...colorsShades
    },
    primaryColor: 'HR_BLUE',
    primaryShade: {light: 6, dark: 6}
};

function App() {
    const {i18n} = useTranslation();
    const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
        key: 'mantine-color-scheme',
        defaultValue: 'light',
        getInitialValueInEffect: true
    });
    const toggleColorScheme = useCallback(
        (value?: ColorScheme) =>
            setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark')),
        [colorScheme]
    );

    return (
        <ColorSchemeProvider
            colorScheme={colorScheme}
            toggleColorScheme={toggleColorScheme}
        >
            <BrowserRouter>
                <ApiResponseInterceptorProvider>
                    <MantineProvider
                        theme={{
                            colorScheme,
                            datesLocale: i18n.language,
                            ...MantineProviderTheme
                        }}
                        withGlobalStyles
                        withNormalizeCSS
                    >
                        <NotificationsProvider position="top-center">
                            <AppRoutes />
                        </NotificationsProvider>
                    </MantineProvider>
                </ApiResponseInterceptorProvider>
            </BrowserRouter>
        </ColorSchemeProvider>
    );
}

export default App;
