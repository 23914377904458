import {PropsWithChildren, ReactNode, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import i18n from 'i18n';

import {twoFaStore} from 'features/TwoFa/store/useTwoFaStore';
import {BUSINESS_URL} from 'config';
import {RefreshTokensResponseType, RefreshQueueCbType} from 'types/AxiosType';
import showCustomNotification from 'utils/showCustomNotification';

// Api Instance Methods
const customAuthorizationApi = (header: string | null) => {
    return axios.create({
        baseURL: BUSINESS_URL,
        headers: {
            Authorization: header ?? ''
        }
    });
};
const api = axios.create({
    baseURL: BUSINESS_URL
});

const apiRequestInterceptor = (config: AxiosRequestConfig) => {
    const {accessToken, currentBusinessAccount} = twoFaStore.getState();
    if (config.headers) {
        config.headers['Accept-Language'] = i18n.resolvedLanguage;
        currentBusinessAccount?.business_account_id &&
            (config.headers['Business-Account-Id'] = currentBusinessAccount?.business_account_id);
        if (!accessToken) {
            delete config.headers.Authorization;
        } else {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
    }
    return config;
};

const ApiResponseInterceptorProvider = (props: PropsWithChildren) => {
    const {children} = props;
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {fetchUserTokens, fetchUserBusinessInfo, logout} = twoFaStore.getState();

    useEffect(() => {
        const responseInterceptor = (response: AxiosResponse) => {
            return response;
        };

        const errorInterceptor = async (error: unknown | any) => {
            const {config} = error;
            const errorKey = error.response?.data?.error;
            const errorStatus = error.response?.status;
            const originalRequest = config;

            if (errorKey === 'ERR_USER_UNAUTHORIZED') return onNotAuthenticatedError();

            if (errorStatus === 403) return onForbiddenError();

            if (errorKey === 'ERR_ACCESS_TOKEN_EXPIRED') {
                const retryOriginalRequest = new Promise((resolve) => {
                    addRefreshQueue((token) => {
                        originalRequest.headers['Authorization'] = `Bearer ${token?.access_token}`;
                        resolve(axios(originalRequest));
                    });
                }).catch((error) => {
                    const errorStatus = error.response?.status;
                    if (errorStatus === 403) return onForbiddenError();
                    return Promise.reject(error);
                });
                if (!isRefreshing) {
                    isRefreshing = true;
                    const [tokens, statusChanged, error] = await fetchUserTokens();
                    if (!error?.error) {
                        onRefreshSuccessCb(tokens);
                        isRefreshing = false;
                        if (statusChanged) {
                            await fetchUserBusinessInfo();
                        }
                    } else {
                        return onNotAuthenticatedError();
                    }
                    refreshQueue = [];
                }
                return retryOriginalRequest;
            } else {
                return Promise.reject(error);
            }
        };

        // Refresh Token Vars
        let isRefreshing = false;
        let refreshQueue: RefreshQueueCbType[] = [];

        // Refresh Token Methods
        const addRefreshQueue = (cb: RefreshQueueCbType) => {
            refreshQueue.push(cb);
        };
        const onRefreshSuccessCb = (tokens: RefreshTokensResponseType | null) => {
            refreshQueue.map((cb: RefreshQueueCbType) => cb(tokens));
        };

        // Handling specific errors
        const onNotAuthenticatedError = () => {
            showCustomNotification({
                message: t('notAuthenticated') as string,
                type: 'error'
            });
            logout();
        };
        const onForbiddenError = () => {
            navigate('/403');
        };

        const interceptor = api.interceptors.response.use(responseInterceptor, errorInterceptor);

        return () => api.interceptors.response.eject(interceptor);
    }, []);

    return <>{children}</>;
};

// Api Interceptors
api.interceptors.request.use(apiRequestInterceptor);

export {ApiResponseInterceptorProvider, customAuthorizationApi};
export default api;

// import {useNavigate} from 'react-router-dom';
// import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
// import i18n from 'i18n';

// import {twoFaStore} from 'features/TwoFa/store/useTwoFaStore';
// import {BUSINESS_URL} from 'config';
// import showCustomNotification from 'utils/showCustomNotification';
// import {RefreshTokensResponseType, RefreshQueueCbType} from 'types/AxiosType';

// // Api Instance Methods
// const customAuthorizationApi = (header: string | null) => {
//     return axios.create({
//         baseURL: BUSINESS_URL,
//         headers: {
//             Authorization: header ?? ''
//         }
//     });
// };
// const api = axios.create({
//     baseURL: BUSINESS_URL
// });
// const apiRequestInterceptor = (config: AxiosRequestConfig) => {
//     const {accessToken, currentBusinessAccount} = twoFaStore.getState();
//     if (config.headers) {
//         config.headers['Accept-Language'] = i18n.resolvedLanguage;
//         currentBusinessAccount?.business_account_id &&
//             (config.headers['Business-Account-Id'] = currentBusinessAccount?.business_account_id);
//         if (!accessToken) {
//             delete config.headers.Authorization;
//         } else {
//             config.headers.Authorization = `Bearer ${accessToken}`;
//         }
//     }
//     return config;
// };
// const apiResponseInterceptorSuccessCb = (response: AxiosResponse) => {
//     return response;
// };
// const ApiResponseInterceptorErrorCb = async (error: unknown | any) => {
//     const {config} = error;
//     const errorKey = error.response?.data?.error;
//     const errorStatus = error.response?.status;
//     const originalRequest = config;
//     const {fetchUserTokens, fetchUserBusinessInfo} = twoFaStore.getState();

//     if (errorKey === 'ERR_USER_UNAUTHORIZED') onNotAuthenticatedError();

//     if (errorStatus === 403) onNotAuthorizedError();

//     if (errorKey === 'ERR_ACCESS_TOKEN_EXPIRED') {
//         const retryOriginalRequest = new Promise((resolve) => {
//             addRefreshQueue((token) => {
//                 originalRequest.headers['Authorization'] = `Bearer ${token?.access_token}`;
//                 resolve(axios(originalRequest));
//             });
//         }).catch((error) => {
//             return Promise.reject(error);
//         });
//         if (!isRefreshing) {
//             isRefreshing = true;
//             const [tokens, statusChanged, error] = await fetchUserTokens();
//             if (!error?.error) {
//                 onRefreshSuccessCb(tokens);
//                 isRefreshing = false;
//                 if (statusChanged) {
//                     await fetchUserBusinessInfo();
//                 }
//             } else {
//                 onNotAuthenticatedError();
//             }
//             refreshQueue = [];
//         }
//         return retryOriginalRequest;
//     } else {
//         return Promise.reject(error);
//     }
// };

// // Handling specific errors
// const onNotAuthenticatedError = () => {
//     showCustomNotification({message: i18n.t('notAuthenticated') as string});
//     twoFaStore.getState().logout();
// };
// const onNotAuthorizedError = () => {
//     twoFaStore.getState().fetchUserBusinessInfo();
//     showCustomNotification({message: i18n.t('youAreNotAuthorized') as string});
// };
// // Refresh Token Vars
// let isRefreshing = false;
// let refreshQueue: RefreshQueueCbType[] = [];
// // Refresh Token Methods
// const addRefreshQueue = (cb: RefreshQueueCbType) => {
//     refreshQueue.push(cb);
// };
// const onRefreshSuccessCb = (tokens: RefreshTokensResponseType | null) => {
//     refreshQueue.map((cb: RefreshQueueCbType) => cb(tokens));
// };

// // Api Interceptors
// api.interceptors.request.use(apiRequestInterceptor);
// api.interceptors.response.use(apiResponseInterceptorSuccessCb, ApiResponseInterceptorErrorCb);

// export {customAuthorizationApi};
// export default api;
